import IGeneric from "../interfaces/IGeneric";
import IHomeProps from "../interfaces/IHomeProps";
import IForm from "../interfaces/IForm";
import IComponent from "../interfaces/IComponent";
import IMessageBubble from "../interfaces/IMessageBubble";
import IMessage from "../interfaces/IMessage";
import IHeaders from "../interfaces/IHeaders";

import "../styles/Home.css";
import { Panel } from "../components/Panel";
import { MessageBubble } from "../components/MessageBubble";
import LoadingPulse from "../components/LoadingPulse";
import { refreshCookies, clearCookies } from "../service/PrinSIX";

import { extractComponents, getFormComponents } from "../service/Components";
import { getFormattedString } from "../utils/mutate";

import React, { useRef, createRef, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { animateScroll } from "react-scroll";
import { useIdleTimer } from 'react-idle-timer';
import { useMsal } from "@azure/msal-react";
import IMenuItem from "../interfaces/IMenuItem";

const Home: React.FC<IHomeProps> = ({
    mainRef,
    resumeHeader,
    menuItem,
    menusCallback,
    loginCallback
}) => {
    const homeRef = createRef<HTMLDivElement>();
    const formRef = createRef<HTMLFormElement>();
    const messagePanelRef = useRef<HTMLDivElement>(null);
    const formPanelRef = useRef<HTMLDivElement>(null);
    const messageSpacerRef = createRef<HTMLDivElement>();

    useEffect(() => {
        window.HSStaticMethods.autoInit();
    });
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(true);
    const [formHistory, setFormHistory] = useState<IForm[]>([]);
    const [updateMode, setUpdateMode] = useState(false);
    const [messageBubbles, setMessageBubbles] = useState<IMessageBubble[]>([]);
    const [parentState, setParentState] = useState<any>({});
    const [errorState, setErrorState] = useState<any>({});
    const [currentForm, setCurrentForm] = useState<IForm>();
    const [previousForm, setPreviousForm] = useState<IForm>();
    const [componentList, setComponent] = useState<IComponent[]>([]);
    const [formToSubmit, setFormToSubmit] = useState<IGeneric>({});
    const [submittedMessageText, setSubmittedMessageText] = useState<string>("");
    const [newUserMessage, setNewUserMessage] = useState<IMessageBubble>({ messages: [] });
    const [headerState, setUpdateHeaders] = useState<IHeaders>({});
    const [state, setState] = useState<string>('Active');
    const { instance } = useMsal();

    const chatView = process.env.REACT_APP_CHAT_VIEW === "true";
    const timeout = parseFloat(process.env.REACT_APP_SESSION_TIMEOUT_MINUTES ?? "0") * 60000;
    const promptBeforeIdle = parseFloat(process.env.REACT_APP_SESSION_PROMPT_BEFORE_TIMEOUT_MINUTES ?? "0") * 60000;
    const throttle = 500;

    const onIdle = () => {
        setState('Idle');
        clearCookies();
        setUpdateHeaders({});
        menusCallback(undefined);
        loginCallback(false);
        setMessageBubbles([]);
        clearForm();
        instance && instance.clearCache();
    };

    const onActive = () => {
        //console.log("Active");
        state !== "Idle" && setState('Active');
    };

    const onPrompt = async () => {
        setState('Prompted');
    };

    const { activate } = useIdleTimer({
        events: [],
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle
    });

    const handleStillHere = () => {
        activate();
        refreshCookies();
    };

    const clearForm = () => {
        setNewUserMessage({ messages: [] });
    };

    const handleChildStateChange = (props: any, inputValue: any) => {
        //console.log(inputValue);
        setIsEdit(true);
        const { elementId, attributes, title } = props;
        attributes.value = inputValue;
        attributes.valueOverride = undefined;
        const formattedString = getFormattedString(props);
        addValueToForm(elementId, inputValue, title, formattedString);

        // Update the parent state based on the child state and component
        setParentState((prevParentState: any) => {
            return {
                ...prevParentState,
                [elementId]: inputValue,
            };
        });
    };

    const getFirstError = (): IGeneric | undefined => {
        for (const elementId in errorState) {
            const error: IGeneric = errorState[elementId];
            if (error) {
                const component = componentList.filter((component) => component && component.elementId === elementId);
                return {
                    elementId,
                    error,
                    containerRef: component.length > 0 ? component[0].containerRef : undefined,
                    inputRef: component.length > 0 ? component[0].inputRef : undefined,
                };
            }
        }

        return undefined;
    };

    const handleErrors = (error: IGeneric, elementId: string) => {
        setErrorState((prevParentState: any) => ({
            ...prevParentState,
            [elementId]: error,
        }));
    };

    const updateFormHistoryCallback = (formsModel: IForm[], preserve: boolean) => {
        if (!preserve)
            formHistory.length = 0;
        formHistory.unshift(...formsModel);
        setFormHistory([...formHistory]);
    };

    const updateMessagesStateCallback = (messages: IMessageBubble[], preserve: boolean) => {
        if (!preserve)
            messageBubbles.length = 0;
        messageBubbles.unshift(...messages);
        setMessageBubbles([...messageBubbles]);
    };

    const addFormHistoryCallback = (formModel: IForm) => {
        formHistory.push(formModel);
        setFormHistory([...formHistory]);
    };

    const addNewMessageCallback = (messageBubble: IMessageBubble) => {
        messageBubbles.push(messageBubble);
        setMessageBubbles([...messageBubbles]);
    };

    const journeyTriggerCallback = (journeyTrigger: IGeneric) => {
        currentForm && (currentForm.workflow = journeyTrigger.name);
        setCurrentForm(currentForm);
        fetchData(currentForm, journeyTrigger, undefined);
    }

    const fetchData = async (form: IForm | undefined, formData: IGeneric | undefined, mwnuItem: IMenuItem | undefined) => {
        setLoading(true);
        try {
            const newForm = await getFormComponents(
                setState,
                formData,
                setComponent,
                menusCallback,
                updateFormHistoryCallback,
                updateMessagesStateCallback,
                addFormHistoryCallback,
                addNewMessageCallback,
                setUpdateHeaders,
                headerState,
                messageBubbles,
                form,
                resumeHeader,
                menuItem
            );
            if (newForm) {
                const { submittedMessageText, clearApplicant } = newForm;
                setCurrentForm(newForm);
                setSubmittedMessageText(submittedMessageText || " ");

                if (clearApplicant) {
                    //setupdateApplicantIdentifier(undefined);
                }

            }
        } catch (error) {
            //console.log(error);
        } finally {
            setErrorState({});
            setFormToSubmit({});
            setNewUserMessage({ messages: [] });
            setParentState({});
            setLoading(false);
            setIsEdit(false);
            activate();
        }
    };

    const addValueToForm = (
        elementId: string,
        value: any,
        title: string | undefined,
        formattedMessage: string | undefined,
    ) => {
        setFormToSubmit((prevState: IGeneric) => {
            return {
                ...prevState,
                [elementId]: value,
            };
        });

        if (formattedMessage || (value && title)) {
            if (newUserMessage.messages.length === 0 || !newUserMessage.messages.some((obj) => obj.elementId === elementId))
                newUserMessage.messages.push({
                    elementId,
                    value,
                    displayValue: formattedMessage,
                    title,
                });
            else
                newUserMessage.messages = newUserMessage.messages.map((item) => {
                    if (item.elementId === elementId) {
                        return { ...item, value, displayValue: formattedMessage };
                    }
                    return item;
                });
        }
        else if (newUserMessage.messages.some((obj) => obj.elementId === elementId))
            newUserMessage.messages = newUserMessage.messages.filter((item) => item.elementId !== elementId);

        setNewUserMessage({ ...newUserMessage });
    };

    const submitHiddenValue = (
        elementId: string,
        value: any,
        title?: string,
        formattedMessage?: string,
    ) => {
        addValueToForm(elementId, value, title, formattedMessage);
    };

    const handleEnter = (component: IComponent) => {
        let nextIndex = componentList.indexOf(component) + 1;
        while (nextIndex < componentList.length) {
            let nextComponent = componentList[nextIndex];
            if (nextComponent.inputRef?.current) {
                nextComponent?.inputRef?.current?.focus();
                break;
            }
            nextIndex++;
        }
    }

    const handleSubmit = async () => {
        //console.log(formToSubmit);
        setIsEdit(false);
        const firstError = getFirstError();
        if (firstError) {
            firstError.error.show = true;
            handleErrors(firstError.error, firstError.elementId);
            scrollToFocus(firstError);
            return;
        }

        setUpdateMode(false);

        submitHiddenValue("submit", submittedMessageText || " ", undefined, submittedMessageText || " ");

        const uniqueMessages = getUniqueMessages(newUserMessage.messages);

        setNewUserMessage((prevState) => ({
            ...prevState,
            ...uniqueMessages,
        }));

        const formBody: { [key: string]: IGeneric } = {};

        for (const key in parentState) {
            if (parentState.hasOwnProperty(key)) {
                formBody[key] = parentState[key];
            }
        }

        const newMessage: IMessageBubble = {
            userMessage: true,
            bubbleId: `user-message-bubble-${messageBubbles.filter((messageBubble) => messageBubble.userMessage).length + 1}`,
            formName: currentForm?.name,
            workflowName: currentForm?.workflow,
            actionId: currentForm?.actionId,
            formData: { ...formBody, submittedMessageText },
            messages: newUserMessage?.messages,
            disabled: false,
            messageBubbleRef: createRef<HTMLDivElement>(),
        };

        if (newMessage.messages.length > 0) {
            addNewMessageCallback(newMessage);
        }

        await fetchData(currentForm, {
            ...formToSubmit,
            "submit": submittedMessageText ?? " ",
        }, undefined);
    };

    const getUniqueMessages = (messages: IMessage[]) => {
        const uniqueMessages: IMessage[] = [];
        for (let i = messages.length - 1; i >= 0; i--) {
            const element = messages[i];
            const titles: string[] = [];
            uniqueMessages.forEach((el) => {
                titles.push(`${(el.title || "")}${el.elementId}`);
            });
            if (!titles.includes(`${(element.title || "")}${element.elementId}`)) {
                uniqueMessages.push(messages[i]);
            }
        }
        uniqueMessages.reverse();

        return uniqueMessages;
    };

    const getUniqueMessageBubbles = (messageBubbles: IMessageBubble[]) => {
        const uniqueMessageBubbles: IMessageBubble[] = [];
        for (let i = messageBubbles.length - 1; i >= 0; i--) {
            const messageBubble = messageBubbles[i];
            const foundMessageBubble = uniqueMessageBubbles.find((uniqueMessageBubble: IMessageBubble) => uniqueMessageBubble.formName === messageBubble.formName && uniqueMessageBubble.workflowName === messageBubble.workflowName && (uniqueMessageBubble.actionId ?? 0 === messageBubble.actionId ?? 0) && uniqueMessageBubble.userMessage === messageBubble.userMessage);
            if (!foundMessageBubble) {
                uniqueMessageBubbles.push(messageBubble);
            }
        }
        uniqueMessageBubbles.reverse();
        return uniqueMessageBubbles;
    };

    const displayPreviousForm = async (messageBubble: IMessageBubble) => {
        setParentState({});
        setUpdateMode(true);
        setPreviousForm(currentForm);

        const { formName, formData, workflowName, bubbleId, actionId } = messageBubble;

        const formToDisplay = formHistory.find(
            (form) => form.name === formName && form.workflow === workflowName && (form.actionId ?? 0) === (actionId ?? 0),
        );// ?? currentForm;

        if (formToDisplay === undefined) {
            return;
        }
        setSubmittedMessageText(formToDisplay.submittedMessageText || " ");

        clearForm();

        const components = extractComponents(formToDisplay.fields);

        await components.forEach((component: IComponent) => {
            if (component) {
                if (component.jsonName === "input_submit") {
                    component.editedMessageId = bubbleId;
                    component.isEdit = true;
                }

                const valueToEdit: string | number | string[] | IGeneric[] = formData !== undefined && formData[`${component.elementId}`];

                if (valueToEdit)
                    handleChildStateChange(component, valueToEdit);
            }
        });

        setComponent(components);
        setErrorState({});
        setCurrentForm(formToDisplay);
        setIsEdit(false);
    };

    const handleCancel = () => {
        if (previousForm === undefined || previousForm == null) return;

        setParentState({});
        setUpdateMode(false);

        clearForm();

        const components = extractComponents(previousForm.fields);

        // components.forEach((comp: IComponent) => {
        //   if (comp && comp.attributes) {
        //     comp.attributes.value = "";
        //   }
        // });

        setComponent(components);
        setErrorState({});
        setCurrentForm(previousForm);
        setIsEdit(false);
    };

    const handleAddress = (props: IGeneric, addressComponents: google.maps.GeocoderAddressComponent[]) => {
        if (!addressComponents) return;
        // setIsAddress(true);
        setParentState({});
        const { elementId, attributes } = props;

        const { buildingNumber, street, locality, town, county, buildingName, subBuiildingName, subBuiildingNumber, country, postcode } = attributes;

        const typeMapping: IGeneric = {
            "street_number": buildingNumber,
            "premise": buildingName,
            "route": street,
            locality,
            "postal_town": town,
            "postal_code": postcode,
            "administrative_area_level_2": county,
            "administrative_area_level_1": country
        };

        formToSubmit[elementId] = addressComponents;

        componentList.filter((component: IComponent) => component).forEach((component: IComponent) => {
            const addressElementId = component.elementId ?? "";
            for (const type in typeMapping) {
                if (typeMapping[type] && typeMapping[type] === addressElementId) {
                    const typeComponents = addressComponents.filter((addressComponent: google.maps.GeocoderAddressComponent) => addressComponent.types.includes(type));
                    const newAttributes = component.attributes ?? {};
                    newAttributes.valueOverride = true;
                    if (typeComponents.length > 0)
                        newAttributes.value = typeComponents[0].long_name;
                    else
                        newAttributes.value = undefined;

                    component.attributes = newAttributes;
                    formToSubmit[addressElementId] = newAttributes.value;

                    const formattedMessage = getFormattedString(component) ?? undefined;
                    if (formattedMessage || (newAttributes.value !== "" && component.title !== "")) {
                        if (newUserMessage.messages.length === 0 || !newUserMessage.messages.some((obj) => obj.elementId === addressElementId))
                            newUserMessage.messages.push({
                                elementId: addressElementId,
                                value: newAttributes.value,
                                displayValue: formattedMessage,
                                title: component.title,
                            });
                        else
                            newUserMessage.messages = newUserMessage.messages.map((item) => {
                                if (item.elementId === addressElementId) {
                                    return { ...item, value: newAttributes.value, displayValue: formattedMessage };
                                }
                                return item;
                            });
                    }
                    else if (newUserMessage.messages.some((obj) => obj.elementId === addressElementId))
                        newUserMessage.messages = newUserMessage.messages.filter((item) => item.elementId !== addressElementId);
                }
            }
        });
        setFormToSubmit({ ...formToSubmit });
        setNewUserMessage({ ...newUserMessage });
        setComponent(componentList);
    };

    function getMainLayoutHeight(): number {
        const mainElement = mainRef.current;
        if (mainElement) {
            let siblingsHeight = 0;
            let sibling = mainElement?.previousElementSibling as HTMLElement;
            while (sibling) {
                siblingsHeight += sibling?.offsetHeight ?? 0;
                sibling = sibling.previousElementSibling as HTMLElement;
            }
            sibling = mainElement?.nextElementSibling as HTMLElement;
            while (sibling) {
                siblingsHeight += sibling?.offsetHeight ?? 0;
                sibling = sibling.nextElementSibling as HTMLElement;
            }
            return window.innerHeight - siblingsHeight;
        }
        return window.innerHeight;
    }

    function setElementHeights() {
        if (isMobile)
            return;
        const mainLayoutHeight = getMainLayoutHeight();

        const homeElement = homeRef.current;
        const homeHeight = mainLayoutHeight - 34;
        homeElement && (homeElement.style.minHeight = `${homeHeight}px`);

        if (!chatView) {
            const formPanelHeight = homeHeight - 56;
            formPanelRef.current && (formPanelRef.current.style.minHeight = `${formPanelHeight}px`);
            return;
        }

        const messagePanelElement = messagePanelRef.current;
        let messagePanelHeight = homeHeight - 34;
        messagePanelElement && (messagePanelElement.style.height = `${messagePanelHeight}px`);

        const spacerElement = messageSpacerRef.current;
        if (spacerElement?.offsetTop ?? 0 > messagePanelHeight / 2) {
            let spacerHeight = messagePanelHeight / 2;
            spacerElement && (spacerElement.style.height = `${spacerHeight}px`);
        }
    }

    function setFocus(firstError: IGeneric | undefined) {
        let inputRef = undefined;
        if (firstError)
            inputRef = firstError.inputRef;
        else {
            const inputComponents = componentList.filter((component) => component && component.inputRef);
            if (inputComponents.length > 0)
                inputRef = inputComponents[0].inputRef;
        }
        if (inputRef?.current)
            inputRef.current.focus();
    }

    function scrollToFocus(firstError: IGeneric | undefined) {
        if (!loading && messageBubbles.length > 0 && !isEdit) {
            const scrollMessages = !updateMode ?
                messageBubbles :
                messageBubbles.filter(
                    (message) => message.formName === (currentForm?.name ?? message.formName) && message.workflowName === (currentForm?.workflow ?? message.workflowName)
                );
            if (scrollMessages && scrollMessages.length > 0) {
                const messageToScroll = scrollMessages[scrollMessages.length - 1];
                const scrollPosition = messageToScroll.messageBubbleRef?.current?.offsetTop ?? 0;

                if (firstError && firstError.error.show) {
                    setFocus(firstError);
                    animateScroll.scrollTo((firstError.containerRef?.current?.offsetTop ?? 0) - 56);
                }
                else {
                    if (isMobile) {
                        if (updateMode)
                            animateScroll.scrollTo((formPanelRef.current?.offsetTop ?? 0) - 56);
                        else
                            animateScroll.scrollTo(scrollPosition - 56);
                    }
                    else {
                        animateScroll.scrollToTop();
                        if (!updateMode) {
                            if (chatView)
                                animateScroll.scrollTo(scrollPosition - 250, { containerId: "message-panel" });
                            else
                                messageSpacerRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
                        }
                    }
                    setFocus(undefined);
                }
            }
        }
    };

    useEffect(() => {
        fetchData(undefined, {}, undefined);
        // eslint-disable-next-line
    }, [resumeHeader]);

    useEffect(() => {
        menuItem?.title && fetchData(undefined, {}, menuItem);
        // eslint-disable-next-line
    }, [menuItem]);

    useEffect(() => {
        setElementHeights();
        scrollToFocus(getFirstError());
        // eslint-disable-next-line
    });

    return (
        <div
            id={chatView ? "home" : "nochat-home"}
            ref={homeRef}
        >
            <form ref={formRef}>
                <Panel
                    id="message-panel"
                    classes={!isMobile ? "desktop-message-panel" : ""}
                    panelRef={messagePanelRef}
                >
                    {loading && messageBubbles.length === 0 && <LoadingPulse />}
                    {state === "Idle" &&
                        <MessageBubble
                            message={{
                                userMessage: false,
                                bubbleId: `idle-message-bubble`,
                                messages: [{
                                    elementId: `idle_message`,
                                    value: process.env.REACT_APP_SESSION_TIMEOUT_MESSAGE_BUBBLE,
                                }],
                                messageBubbleRef: createRef<HTMLDivElement>(),
                            }}
                            callbackDisplayPreviousForm={() => null}
                            messageBubbleRef={createRef<HTMLDivElement>()}
                        />
                    }
                    {messageBubbles.length > 0 ?
                        getUniqueMessageBubbles(messageBubbles).map((messageBubble) => {
                            const { bubbleId, disabled, messageBubbleRef } = messageBubble;
                            return (
                                <MessageBubble
                                    message={{ ...messageBubble, disabled: disabled || (currentForm?.noEdit ?? false) || updateMode || loading }}
                                    callbackDisplayPreviousForm={displayPreviousForm}
                                    key={bubbleId || Math.random().toString()}
                                    messageBubbleRef={messageBubbleRef}
                                />
                            );
                        }) :
                        <></>
                    }
                    {state === "Prompted" &&
                        <MessageBubble
                            message={{
                                userMessage: false,
                                bubbleId: `idle-prompt-message-bubble`,
                                messages: [{
                                    elementId: `idle_prompt_message`,
                                    value: process.env.REACT_APP_SESSION_PROMPT_MESSAGE_BUBBLE,
                                }],
                                messageBubbleRef: createRef<HTMLDivElement>(),
                            }}
                            callbackDisplayPreviousForm={() => null}
                            messageBubbleRef={createRef<HTMLDivElement>()}
                        />
                    }
                    {state === "Unauthorized" &&
                        <MessageBubble
                            message={{
                                userMessage: false,
                                bubbleId: `unauthorized-message-bubble`,
                                messages: [{
                                    elementId: `unauthorized_message`,
                                    value: process.env.REACT_APP_SESSION_UNAUTHORIZED_MESSAGE_BUBBLE,
                                }],
                                messageBubbleRef: createRef<HTMLDivElement>(),
                            }}
                            callbackDisplayPreviousForm={() => null}
                            messageBubbleRef={createRef<HTMLDivElement>()}
                        />
                    }
                    {!loading && <div id="message-spacer" ref={messageSpacerRef}></div>}
                </Panel>
                <Panel
                    id="form-panel"
                    panelRef={formPanelRef}
                >
                    {loading && <LoadingPulse />}
                    {state === "Prompted" && (
                        <div>
                            <div className="text nowrap" id="idle-prompt-open" dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_SESSION_PROMPT_MESSAGE_FORM ?? "" }} />
                            <div className="submit nowrap" id="idle-submit">
                                <button
                                    type="button"
                                    className="submit-button"
                                    onClick={handleStillHere}
                                    autoFocus={true}
                                >
                                    {process.env.REACT_APP_SESSION_PROMPT_BUTTON_TEXT}
                                </button>
                            </div>
                        </div>
                    )}
                    {state === "Idle" && (
                        <div>
                            <div className="text nowrap" id="idle-prompt-open" dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_SESSION_TIMEOUT_MESSAGE_FORM ?? "" }} />
                        </div>
                    )}
                    {state === "Unauthorized" && (
                        <div>
                            <div className="text nowrap" id="unauthorized-open" dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_SESSION_UNAUTHORIZED_MESSAGE_FORM ?? "" }} />
                        </div>
                    )}
                    {state === "Active" && !loading && (
                        <div
                            id="components"
                            className={`${currentForm?.name}${currentForm?.className && " " + currentForm?.className}`}
                        >
                            {componentList.filter(item => item).map((item, index) => {
                                const Component = Object.values(item)[1];

                                const elementId = item.elementId || "";

                                return (
                                    <Component
                                        {...item}
                                        onStateChange={(inputValue: any) =>
                                            handleChildStateChange(item, inputValue)
                                        }
                                        onError={(error: IGeneric) =>
                                            handleErrors(error, elementId)
                                        }
                                        error={errorState[elementId]}
                                        journeyTriggerCallback={(journeyTrigger: IGeneric) =>
                                            journeyTriggerCallback(journeyTrigger)
                                        }
                                        onSubmit={handleSubmit}
                                        onEnter={() =>
                                            handleEnter(item)
                                        }
                                        onCancel={handleCancel}
                                        isEdit={updateMode}
                                        key={`${currentForm?.workflow}_${currentForm?.name}_${currentForm?.actionId}_${elementId} _component`}
                                        handleAddressChange={handleAddress}
                                    />
                                );
                            })}
                        </div>
                    )}
                </Panel>
            </form>
        </div>
    );
};

export default Home;
